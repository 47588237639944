<template>
  <b-container>
    <div class="text-center my-5">
      <MTBLoader v-if="items.length === 0" />
    </div>

    <h1 class="mt-5 text-primary">{{categoryTitle}}</h1>
    <h5 class="mb-3">{{subtitle}}</h5>

    <!-- SEARCH -->
    <b-row v-if="items.reports !== 0" class="mt-5 mb-5">
      <b-col md="6" class="mx-auto">
        <b-form-input border-variant="light" class="search-bar shadow mb-2" type="search" v-model="search" placeholder="Search Report..."></b-form-input>
      </b-col>
    </b-row>



    <!-- CARDS GRID -->
  <b-row>
    <b-col>
       <div class="my-grid">
            <b-card
              v-for="(report, index) in filterItemsSearch"
              :key="index"
              img-width="250"
              fluid-grow
              class="cardUnlocked"
              text-variant="white"
              style="  border-radius: 20px;"
              :img-src="report.cover"
              img-alt="Image"
              overlay
              @click="goToReport(report)"
              >
              <div class="card-content">
              </div>
            </b-card>
          </div>
        </b-col>
      </b-row>

    <!-- PAGINATION -->
    <!-- <b-row v-if="items.length > 0">
      <b-col>
        <div class="mt-1">
          <b-pagination @page-click="scrollTop" pills class="mt-5" v-model="currentPage" :total-rows="rows" :per-page="perPage" align="center"></b-pagination>
        </div>
      </b-col>
    </b-row> -->

  </b-container>
</template>

<script>
import MTBLoader from "../components/MTBLoader";
import util from "../support/util";
export default {
  name: "ReportGrid",
  components: { MTBLoader },
  props: ["items", "category","categorySubtitle"],
  data() {
    return {
      search: "",
      };
  },
  computed: {
    reports() {
      return this.$props.items;
    },
    categoryTitle(){
      return this.$props.category
    },
    subtitle(){
      return this.$props.categorySubtitle
    },
    paginatedCards() {
      const { currentPage, perPage } = this;
      const start = (currentPage - 1) * perPage;
      const end = currentPage * perPage;
      return this.filteredItems.slice(start, end);
    },
    rows() {
      return this.filteredItems.length;
    },
    filteredItems() {
      return this.filterItemsSearch;
    },
    filterItemsSearch() {
      return this.filterItembySearch(this.items);
    },
  },
  created() {
    this.noImage = util.noImage;
    this.loadLogo = util.loadLogo;
    this.scrollTop = util.scrollTop;
  },
  methods: {
    goToReport(report){
      this.$router.push({ name: 'Report', params: { name: report.slug } })
    },
    filterItembySearch(items) {
      return items.filter((item) => {
        return item["title"].toLowerCase().includes(this.search.toLowerCase());
      });
    },
  },
};
</script>

<style scoped>
.my-grid {
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1.5rem;
}
.my-grid > * {
  width: 100%;
  max-width: 20rem;
}
.card:hover {
  -webkit-transform: scale(1.02, 1.02);
  transform: scale(1.02, 1.02);
}
.card {
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.card-img{
  border-radius:8px;
}
.search-bar {
  border-radius: 10px;
  border: none;
}
</style>
