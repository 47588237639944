<template>
  <b-container>
    <div class="return d-none d-md-block">
      <router-link to="/reports"> Back to Reports</router-link>
    </div>

    <div class="text-center my-5">
      <MTBLoader v-if="items.length === 0" />
    </div>

    <ReportsGrid v-if="items.length !== 0" :category="category" :items="items" ref="grid" />

  </b-container>
</template>

<script>
import ReportsGrid from "@/components/ReportsGrid";
import util from "../support/util";
import MTBLoader from "@/components/MTBLoader";

export default {
  data() {
    return {
      category: null,
      items: [],
    };
  },
  components: { ReportsGrid, MTBLoader },
  created() {
    util.scrollTop();
    this.$store.dispatch("loadReports").then(() => {
      if (this.$store.state.reports.length !== 0) {
        this.items = this.$store.state.reports.filter((report) => {
          return report.category === this.$route.params.name && !report.draft;
        })
        this.category = this.items[0].categoryTitle
      }
    })
  },
}
</script>

<style scoped>
.return {
  position: absolute;
  top: 3vh;
  left: 4vw;
  color: #0275d8;
  z-index: 1 !important;
}
</style>
